* {
	box-sizing: border-box;
}

body {
	background-color: white;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.d-flex {
	display: flex;
}

.container {
	display: flex;
	min-height: 100dvh;
	justify-content: center;
}

.content {
	width: 100%;
	max-width: 1200px;
	margin: 50px;
}

.row {
	display: flex;
	flex-direction: row;
}

.col {
	display: flex;
	flex-direction: column;
}

.justify-content-between {
	justify-content: space-between;
}

.justify-content-center {
	justify-content: center;
}

.align-items-center {
	align-items: center;
}

.gap {
	gap: 10px;
}

.flex-fill {
	flex: 1;
}

.flex-wrap {
	flex-wrap: wrap;
}

.text-nowrap {
	text-wrap: nowrap;
}

.padding {
	padding: 10px;
}

.title {
	font-size: 60px;
}
.subtitle {
	font-size: 50px;
}

.campo {
	padding: 12px 16px;
	border-radius: 100px;
	background-color: #fff;
	border-width: 1px;
	border-style: solid;
	border-color: #dee2e6;
	margin-bottom: 8px;
	outline: none;
	color: black;
	font-size: 16px;
	box-sizing: border-box;
}
.campo::-webkit-input-placeholder {
	/* Edge */
	color: #ccc;
}
.campo:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #ccc;
}
.campo::placeholder {
	color: #ccc;
}

.campo-check {
	display: block;
	padding: 12px 16px;
	border-radius: 100px;
	background-color: #fff;
	border-width: 1px;
	border-style: solid;
	border-color: #dee2e6;
	margin-bottom: 8px;
	outline: none;
	color: black;
	font-size: 18px;
	box-sizing: border-box;
	cursor: pointer;
}
/* .campo-check:has(input[type=checkbox]:checked){
  background-color: #1e4c79;
  color: white;
} */

.botao {
	cursor: pointer;
	padding: 16px 24px;
	border-radius: 100px;
	border-width: 2px;
	border-style: solid;
	border-color: #1e4c79;
	background-color: #ffffff;
	color: #1e4c79;
	font-size: 18px;

	text-transform: uppercase;
	font-weight: bold;
	font-size: 15px;
}

.botao-grupo {
	position: relative;
	display: flex;
}
.botao-grupo .botao {
	cursor: pointer;
	width: auto !important;
	padding: 16px 24px;
	border-radius: 0;
	font-size: 18px;
	margin-top: 8px;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 15px;
	border-right: none;
}
.botao-grupo .botao:first-child {
	border-top-left-radius: 100px;
	border-bottom-left-radius: 100px;
}
.botao-grupo .botao:last-child {
	padding: 16px 16px;
	border-top-right-radius: 100px;
	border-bottom-right-radius: 100px;
	border-right: 2px solid #1e4c79;
}

.botao:hover {
	background-color: #f6f6f6;
}
.botao:active {
	background-color: #eeeeee;
}
.botao.blue {
	background-color: hsl(210, 60%, 30%);
	color: white;
}
.botao.blue:hover {
	background-color: hsl(210, 60%, 25%);
}
.botao.blue:active {
	background-color: hsl(210, 60%, 20%);
}

.botao.orange {
	border-color: rgb(240, 129, 56);
	background-color: hsl(24, 86%, 58%);
	color: white;
}
.botao.orange:hover {
	background-color: hsl(24, 86%, 53%);
}
.botao.orange:active {
	background-color: hsl(24, 86%, 48%);
}

.texto {
	padding: 16px 24px;
	text-align: center;
	color: #ffffff;
	font-size: 18px;
	margin-top: 8px;
	font-weight: bold;
}

.dropdown {
	position: absolute;
	left: 0;
	right: 0;
	top: 100%;
	cursor: pointer;
	width: auto;
	padding: 0;
	border-radius: 20px;
	background-color: #ffffff;
	color: #1e4c79;
	text-transform: capitalize;
	font-weight: bold;
	box-sizing: border-box;
	box-shadow: 0 5px 10px rgb(0, 0, 0, 0.25);
	overflow: hidden;
	border-width: 2px;
	border-style: solid;
	border-color: #ffffff;
}
.dropdown ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.dropdown ul li {
	border-bottom: 1px solid #eee;
	margin: 0;
	padding: 12px 24px;
	box-sizing: border-box;
	font-size: 14px;
}
.dropdown ul li:hover {
	background-color: #f6f6f6;
}
.dropdown ul li.active {
	color: #ffffff;
	background-color: #1e4c79;
}

@keyframes float {
	0% {
		filter: drop-shadow(0 5px 10px rgba(31, 77, 122, 0.3));
		transform: translatey(0px);
	}
	50% {
		filter: drop-shadow(0 25px 10px rgba(31, 77, 122, 0.2));
		transform: translatey(-20px);
	}
	100% {
		filter: drop-shadow(0 5px 10px rgba(31, 77, 122, 0.3));
		transform: translatey(0px);
	}
}

.card {
	padding: 24px;
	border-radius: 20px;
	background-color: #fff;
	border-width: 1px;
	border-style: solid;
	border-color: #dee2e6;
	margin-bottom: 8px;
	outline: none;
	color: black;
	font-size: 16px;
	box-sizing: border-box;
}

.cardInfoTitle {
	font-size: 14px;
	font-weight: 400;
	color: #555;
}

.card hr:last-child {
	display: none;
}
.card * hr:last-child {
	display: none;
}
